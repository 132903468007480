/* 
dark blue: #015a9f
light blue: #029ccf
*/

.pano-container{
  width: 100vw;
  height: 100vh;
} 

.hologram-container {
  z-index: 10;
  
}
.globe-container {
	z-index: 11;
}
.hologram-container.bubble-container {
  pointer-events: none;
}

.hotspot{
  width: 60px;
  height: 60px;
  border-radius: 0;
}

@keyframes pulse-border {
	0% {
		transform: scale(0);
		opacity: 1
	}
	to {
		transform: scale(1);
		opacity: 0
	}
}