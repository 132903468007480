@media (max-width: 1023px) {
    .lecture-hall .flag-container {
        display: block !important;
    }
    .lecture-hall .flag-container .flag {
        margin: 50px auto 0 !important;
        max-width: 80% !important;
    }
    .nav-center {
        display: none !important;
    }
}


.clickable {
    pointer-events: none;
    position: absolute;
}
.clickable > div {
    pointer-events: all;    
}

.notClickable > div a {
    pointer-events: none;
    text-align: center;
}

.title > div a {
    pointer-events: none;
    color: #015a9b;
    background-color: #ddeff9;
}