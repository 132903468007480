.navbar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #005a9b;
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    padding: 2px 10px;
    min-height: 50px;
    font-family: "News Cycle", sans-serif;

    &.atrium .nav-option[data-icon=arrow-back-256] {
        font-size: 0;
    }

    &:after {
        transition: all 0.3s ease 0s;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: -30px;
        width: 250px;
        background: rgba(255,255,255,0.3);
        z-index: 1;
        transform: skewX(-200deg);
    }
    &:hover:after {
        width: 80%;
    }


    .nav-option {
        text-decoration: none;
        cursor: pointer;
        color: #fff;
        padding: 15px 25px;
        z-index: 1000;
        
        &:hover {
            color: #d0dfeb;
        }

        &.hasSubmenu .submenu {
            display: none;
        }

        &.hasSubmenu:hover .submenu {
            display: block;
        }

        &[data-icon=home-256] {
            background: url(/assets/icons/home-256.png) 50%/contain no-repeat transparent;
        }
        &[data-icon=arrow-back-256] {
            background: url(/assets/icons/arrow-back-256.png) 0 50%/contain no-repeat transparent;
            padding: 1px 0 0 49px;
        }
    }

    .nav-left {
        display: flex; 
        justify-content: flex-start;
    }
    .nav-center {
        display: flex; 
        justify-content: space-evenly;
        .nav-option {
            padding: 15px;
        }
    }
    .nav-right {
        width: 200px;
        display: flex; 
        justify-content: flex-end;
    }

    .submenu {
        position: absolute;
        bottom: 0;
        background: #005a9b;
        margin: 0 0 60px -15px;
    }

}